import axiosinstance from '../utils/axios'
import setToasterAlert from './Common/Actions/Toaster'
import store from '../store/store'
import { toast } from 'react-toastify'
import axios from 'axios'

const URL = process.env.REACT_APP_API_GATEWAY_URL

export const fetchUser = async () => {
  return axiosinstance.get(URL + 'user/').catch((err) => {
    return err
  })
}

export const searchContents = async (url) => {
  return axiosinstance.post(url).catch((err) => {
    return err
  })
}

export const getDomain = async () => {
  return axiosinstance.get(`${URL}domain/domain_suggestion`).catch((err) => {
    return err
  })
}

export const createNewDomain = async (data) => {
  return axiosinstance.post(`${URL}domain/newdomain`, data).catch((err) => {
    return err
  })
}

export const joinDomain = async (data) => {
  return axiosinstance.post(`${URL}domain/joindomain`, data).catch((err) => {
    return err
  })
}

export const getProposal = async (proposalId) => {
  return axiosinstance.get(`${URL}proposals/${proposalId}`).catch((err) => {
    return err
  })
}

export const getProposals = async () => {
  return axiosinstance.get(`${URL}proposals/`).catch((err) => {
    return err
  })
}

export const postProposals = async (data) => {
  return axiosinstance.post(`${URL}proposals/`, data).catch((err) => {
    return err
  })
}

export const deleteProposals = async (proposal_id) => {
  return axiosinstance
    .delete(`${URL}proposals/delete/${proposal_id}`)
    .catch((err) => {
      return err
    })
}

export const getSearchContent = async (url) => {
  return axiosinstance.get(url).catch((err) => {
    return err
  })
}

export const generateContents = async (url) => {
  return axiosinstance.post(url).catch((err) => {
    return err
  })
}

export const getUserDomain = () => {
  return axiosinstance.get(`${URL}domain/`).catch((err) => {
    return err
  })
}
export const getUserInvites = () => {
  return axiosinstance.get(`${URL}domain/invite`).catch((err) => {
    return err
  })
}
export const getAllDomainList = () => {
  return axiosinstance.get(`${URL}domain/superuser/domainlist`).catch((err) => {
    return err
  })
}
export const postUserProfile = (data) => {
  return axiosinstance.post(`${URL}user/profile`, data).catch((err) => {
    return err
  })
}
export const putUserDomain = (data) => {
  return axiosinstance.put(`${URL}domain/domain`, data).catch((err) => {
    return err
  })
}
export const putSuperUserDomainId = (data) => {
  return axiosinstance
    .put(`${URL}domain/superuser/domainswitch`, data)
    .catch((err) => {
      return err
    })
}
export const putUserInvites = (data) => {
  return axiosinstance.put(`${URL}domain/invite`, data).catch((err) => {
    return err
  })
}
export const postUserInvites = (request) => {
  const uri = `${URL}domain/invite`
  return axiosinstance.post(uri, request).catch((err) => {
    return err
  })
}
export const createSavedContent = (url, request) => {
  return axiosinstance.post(url, request).catch((err) => {
    return err
  })
}
export const getSavedContents = (url) => {
  return axiosinstance.get(url).catch((err) => {
    return err
  })
}

export const getLibraryDoc = (id, axiosProps) => {
  return axiosinstance
    .get(`${URL}library/${encodeURIComponent(id)}`, axiosProps)
    .catch((err) => {
      return err
    })
}

export const addToLibrary = (data) => {
  return axiosinstance.post(`${URL}library/`, data).catch((err) => {
    return err
  })
}

export const updateLibrary = (data) => {
  return axiosinstance.put(`${URL}library/`, data).catch((err) => {
    return err
  })
}

export const deleteLibrary = (data) => {
  return axiosinstance.put(`${URL}library/deleteLibrary`, data).catch((err) => {
    return err
  })
}

export const searchLibrary = (data, axiosProps) => {
  return axiosinstance
    .post(`${URL}library/search`, data, axiosProps)
    .catch((err) => {
      return err
    })
}

export const searchApi = async (body = {}, axiosProps = {}) => {
  return axiosinstance
    .post(`${URL}es-auth/search`, body, axiosProps)
    .catch((err) => {
      return err
    })
}

export const getAnalytic = async (analyticId, startPage = 1, endPage = 50) => {
  return axiosinstance
    .get(
      `${URL}analytic/${analyticId}?start_page=${encodeURIComponent(
        startPage
      )}&end_page=${encodeURIComponent(endPage)}`
    )
    .catch((err) => {
      // toast.error(err.code + " : " + err.message);
      let message = ''
      err.code === 'ERR_NETWORK'
        ? (message = 'Fetching Analytic failed, Reload the page.')
        : (message = `${err.code} : ${err.message}`)
      store.dispatch(
        setToasterAlert({
          message,
          show: true,
          type: 'error'
        })
      )
      return err
    })
}

export const getAnalytics = async () => {
  return axiosinstance.get(`${URL}analytic/`).catch((err) => {
    let message = ''
    err.code === 'ERR_NETWORK'
      ? (message = 'Fetching Analytics failed, Reload the page.')
      : (message = `${err.code} : ${err.message}`)
    store.dispatch(
      setToasterAlert({
        message,
        show: true,
        type: 'error'
      })
    )
    // toast.error(err.code + " : " + err.message);
    return err
  })
}

export const getAsset = async (assetId) => {
  return axiosinstance.get(`${URL}asset/${assetId}`).catch((err) => {
    // toast.error(err.code + " : " + err.message);
    store.dispatch(
      setToasterAlert({
        message: `${err.code} : ${err.message}`,
        show: true,
        type: 'error'
      })
    )
    return err
  })
}

export const getAssets = async (pageNumber, data) => {
  return axiosinstance
    .post(`${URL}asset/pagination/${pageNumber}`, data)
    .catch((err) => {
      let message = ''
      if (err.code === 'ERR_NETWORK' || err.code === undefined) {
        message = 'Fetching Assets failed, Reload the page.'
      } else {
        message = `${err.code} : ${err.message}`
      }
      store.dispatch(
        setToasterAlert({
          message,
          show: true,
          type: 'error'
        })
      )
      return err
    })
}

export const postAssets = async (data) => {
  return axiosinstance.post(`${URL}asset/`, data).catch((err) => {
    return err
  })
}

export const deleteAsset = async (data) => {
  return axiosinstance.post(`${URL}asset/delete`, data).catch((err) => {
    return err
  })
}

export const postAnalytics = async (data) => {
  return axiosinstance.post(`${URL}analytic/`, data).catch((err) => {
    return err
  })
}

export const delAnalytics = async (data) => {
  return axiosinstance.put(`${URL}analytic/`, data).catch((err) => {
    return err
  })
}

export const getuserSearchHistory = async (count) => {
  return axiosinstance
    .get(`${URL}search-history/?limit=${encodeURIComponent(count)}`)
    .catch((err) => {
      return err
    })
}

export const clearUserSearchHistory = async (data) => {
  return axiosinstance.post(`${URL}search-history/clear`, data).catch((err) => {
    return err
  })
}

export const autoSuggestion = async (domain_id, keyword) => {
  return axiosinstance
    .post(
      `${URL}es-auth/suggestion?domain_id=${encodeURIComponent(
        domain_id
      )}&search_keyword=${encodeURIComponent(keyword)}`
    )
    .catch((err) => {
      return err
    })
}

export const autoCompleteAll = async (keyword, axiosProps) => {
  return axiosinstance
    .post(
      `${URL}es-auth/autocompleteAll?search_keyword=${encodeURIComponent(
        keyword
      )}`,
      {},
      axiosProps
    )
    .catch((err) => {
      return err
    })
}

export const generateAPI = async (text, type, domain_id = '') => {
  switch (type) {
    case 'Title':
      return axiosinstance
        .post(
          `${URL}openai/titleGenerator?prompt_input=${encodeURIComponent(text)}`
        )
        .catch((err) => {
          return err
        })
    case 'Generate':
      return axiosinstance
        .post(
          `${URL}openai/text-generator?domain_id=${encodeURIComponent(
            domain_id
          )}&prompt_input=${encodeURIComponent(text)}`
        )
        .catch((err) => {
          return err
        })
    case 'Rewrite':
      return axiosinstance
        .post(`${URL}openai/rephrase?prompt_input=${encodeURIComponent(text)}`)
        .catch((err) => {
          return err
        })
    case 'Summarize':
      return axiosinstance
        .post(`${URL}openai/summarize?prompt_input=${encodeURIComponent(text)}`)
        .catch((err) => {
          return err
        })
    case 'Outline':
      return axiosinstance
        .post(
          `${URL}openai/outlineGenerator?prompt_input=${encodeURIComponent(
            text
          )}`
        )
        .catch((err) => {
          return err
        })
    case 'Notes Summary':
      return axiosinstance
        .post(
          `${URL}openai/notesSummary?prompt_input=${encodeURIComponent(text)}`
        )
        .catch((err) => {
          return err
        })
    case 'Q&A':
      return axiosinstance
        .post(`${URL}openai/q-a?prompt_input=${encodeURIComponent(text)}`)
        .catch((err) => {
          return err
        })
    case 'Grammer Checker':
      return axiosinstance
        .post(
          `${URL}openai/grammarChecker?prompt_input=${encodeURIComponent(text)}`
        )
        .catch((err) => {
          return err
        })
    case 'Simplify':
      return axiosinstance
        .post(`${URL}openai/simplify?prompt_input=${encodeURIComponent(text)}`)
        .catch((err) => {
          return err
        })
    default:
      return false
  }
}
export const analyticsErrorUpdate = async (data) => {
  return axiosinstance.post(`${URL}analytic/errorUpdate`, data).catch((err) => {
    return err
  })
}

export const getProposalBounding = async (
  domain_id,
  proposal_id,
  page_number = 1,
  page_size = 100
) => {
  return axiosinstance
    .post(
      `${URL}proposals/get_bounding_box?domain_id=${encodeURIComponent(
        domain_id
      )}&proposal_id=${encodeURIComponent(
        proposal_id
      )}&page_number=${encodeURIComponent(
        page_number
      )}&page_size=${encodeURIComponent(page_size)}`
    )
    .catch((err) => {
      return err
    })
}

export const saveProposalBounding = async (domain_id, proposal_id, data) => {
  return axiosinstance
    .post(
      `${URL}proposals/save_section?domain_id=${encodeURIComponent(
        domain_id
      )}&proposal_id=${encodeURIComponent(proposal_id)}`,
      data
    )
    .catch((err) => {
      return err
    })
}

export const getExistingPrompts = async () => {
  return axiosinstance.get(`${URL}prompt/`).catch((err) => {
    return err
  })
}

export const generateAPIPrompt = async (prompt, domain_id) => {
  const data = {
    prompt,
    domain_id
  }
  return axiosinstance.post(`${URL}openai/user_prompt`, data).catch((err) => {
    return err
  })
}

export const fetchVariations = async (data, cancelTokenSource = '') => {
  try {
    return await axiosinstance.post(`${URL}es-auth/section_variations`, data, {
      cancelToken: cancelTokenSource?.token
    })
  } catch (err) {
    return err
  }
}

export const removeUploadedFile = (id, uploadType) => {
  return axiosinstance
    .post(`${URL}/removeFile`, { fileId, Type })
    .catch((err) => {
      return err
    })
}

export const fetchLibraryVariations = async (data, axiosProps) => {
  try {
    return await axiosinstance.post(
      `${URL}library/variations`,
      data,
      axiosProps
    )
  } catch (err) {
    return err
  }
}

export const hideAsset = async (data) => {
  return axiosinstance.post(`${URL}asset/hide`, data).catch((err) => {
    return err
  })
}

export const checkIdp = async (data) => {
  try {
    return await axios.post(`${URL}domain/idp`, data)
  } catch (err) {
    return err
  }
}

export const fetchIdp = async () => {
  try {
    return await axiosinstance.get(`${URL}domain/idp`)
  } catch (err) {
    return err?.response
  }
}

export const addIdp = async (data) => {
  try {
    return await axiosinstance.put(`${URL}domain/idp`, data)
  } catch (err) {
    return err?.response
  }
}

export const delIdp = async (id) => {
  try {
    return await axiosinstance.delete(`${URL}domain/idp/${id}`)
  } catch (err) {
    return err?.response
  }
}

export const proposalGroupDelete = async (data) => {
  return axiosinstance
    .post(`${URL}proposals/group-delete`, data)
    .catch((err) => {
      return err
    })
}

export const proposalGroupUpdate = async (data) => {
  return axiosinstance
    .post(`${URL}proposals/group-update`, data)
    .catch((err) => {
      return err
    })
}

export const getNarrativeTags = async () => {
  try {
    return await axiosinstance.get(`${URL}library/tags`)
  } catch (err) {
    return err
  }
}

export const getNarrativeCollections = async () => {
  try {
    return await axiosinstance.get(`${URL}library/collections`)
  } catch (err) {
    return err
  }
}

export const createCollection = async (data) => {
  try {
    return await axiosinstance.post(`${URL}library/collection`, data)
  } catch (err) {
    return err
  }
}

export const deleteCollection = async (data) => {
  try {
    return await axiosinstance.put(`${URL}library/deleteCollection`, data)
  } catch (err) {
    return err
  }
}

export const getChatHistory = async (data) => {
  try {
    return await axiosinstance.post(`${URL}openai/chatbot-history`, data)
  } catch (err) {
    return err
  }
}

export const getSimilarImages = async (data, cancelToken) => {
  try {
    return await axiosinstance.post(`${URL}asset/get_similar_images`, data, {
      cancelToken
    })
  } catch (err) {
    return err
  }
}

export const getTextExtractPageData = async (data) => {
  try {
    return await axiosinstance.post(`${URL}textract/textract_page_data`, data)
  } catch (err) {
    return err
  }
}

export const getPeopleProposals = async () => {
  try {
    return await axiosinstance.get(`${URL}people/`)
  } catch (err) {
    return err
  }
}

export const getPeopleProposalData = async (id) => {
  try {
    return await axiosinstance.get(`${URL}people/${encodeURIComponent(id)}`)
  } catch (err) {
    return err
  }
}

export const savePeopleProposalData = async (data) => {
  try {
    return await axiosinstance.post(`${URL}people/save`, data)
  } catch (err) {
    return err
  }
}

export const savePageData = async (data) => {
  return axiosinstance.post(`${URL}proposals/pagedata`, data).catch((err) => {
    return err
  })
}

export const reprocessPeople = async (data) => {
  return axiosinstance.post(`${URL}people/reprocess`, data).catch((err) => {
    return err
  })
}

export const getPeople = async (data) => {
  return axiosinstance.post(`${URL}people/allpeople`, data).catch((err) => {
    return err
  })
}

export const getStyleSettings = async () => {
  try {
    return await axiosinstance.post(`${URL}analytic/style_settings`)
  } catch (err) {
    return err
  }
}

export const putStyleSettings = async (data) => {
  try {
    return await axiosinstance.put(`${URL}analytic/style_settings`, data)
  } catch (err) {
    return err
  }
}

export const getChartData = async (data) => {
  try {
    return await axiosinstance.post(`${URL}analytic/retrieve_charts_data`, data)
  } catch (err) {
    return err
  }
}

export const getWinStrategy = async (data) => {
  try {
    return await axiosinstance.post(`${URL}analytic/winStrategy`, data)
  } catch (err) {
    return err
  }
}

export const getIntercomSecret = async () => {
  return axiosinstance.get(`${URL}intercom/`).catch((err) => {
    return err
  })
}

export const postAnnotationStatus = async (data) => {
  return axiosinstance
    .post(`${URL}proposals/annotation_status`, data)
    .catch((err) => {
      return err
    })
}

export const getAnnotationUsers = async () => {
  return axiosinstance.get(`${URL}user/annotation-users`).catch((err) => {
    return err
  })
}

export const getSimilarity = async (data) => {
  return axiosinstance.post(`${URL}es-auth/similarity`, data).catch((err) => {
    return err
  })
}

export const getRFxDocuments = async () => {
  return axiosinstance.get(`${URL}rfx/`).catch((err) => {
    let message = ''
    err.code === 'ERR_NETWORK'
      ? (message = 'Fetching RFx documents failed, Reload the page.')
      : (message = `${err.code} : ${err.message}`)
    store.dispatch(
      setToasterAlert({
        message,
        show: true,
        type: 'error'
      })
    )
    // toast.error(err.code + " : " + err.message);
    return err
  })
}

export const getRFx = async (rfxId) => {
  return axiosinstance.get(`${URL}rfx/${rfxId}`).catch((err) => {
    // toast.error(err.code + " : " + err.message);
    let message = ''
    err.code === 'ERR_NETWORK'
      ? (message = 'Fetching RFx document failed, Reload the page.')
      : (message = `${err.code} : ${err.message}`)
    store.dispatch(
      setToasterAlert({
        message,
        show: true,
        type: 'error'
      })
    )
    return err
  })
}

export const rfxAnalysisUpdate = async (data) => {
  try {
    return await axiosinstance.put(`${URL}rfx/rfxSummaryUpdate`, data)
  } catch (err) {
    return err
  }
}

export const postRfx = async (data) => {
  return axiosinstance.post(`${URL}rfx/`, data).catch((err) => {
    return err
  })
}

export const delRfx = async (data) => {
  return axiosinstance.put(`${URL}rfx/`, data).catch((err) => {
    return err
  })
}

export const getUserChatHistory = async (req) => {
  return axiosinstance
    .post(`${URL}openai/chat_session/history`, req)
    .catch((err) => {
      return err
    })
}

export const putChatSessionHistory = async (data) => {
  return axiosinstance
    .post(`${URL}openai/chat_session/update`, data)
    .catch((err) => {
      return err
    })
}

export const generateTitle = async (data) => {
  return axiosinstance
    .post(`${URL}openai/chat_session/title_generate`, data)
    .catch((err) => {
      return err
    })
}

export const getAllSimiarImages = async (data) => {
  return axiosinstance
    .post(`${URL}asset/get_all_similar_images`, data)
    .catch((err) => {
      return err
    })
}

export const hideAllImages = async (data) => {
  return axiosinstance.post(`${URL}asset/hide_all`, data).catch((err) => {
    return err
  })
}

export const chatFeedback = async (data) => {
  try {
    return await axiosinstance.put(`${URL}openai/chat-feedback`, data)
  } catch (err) {
    return err
  }
}

export const chatStop = async (data) => {
  try {
    return await axiosinstance.put(`${URL}openai/chat_stop`, data)
  } catch (err) {
    return err
  }
}

export const chatShare = async (data) => {
  try {
    return await axiosinstance.post(`${URL}openai/chat_share`, data)
  } catch (err) {
    return err
  }
}

export const viewSessionChat = async (sessionId) => {
  return axiosinstance
    .get(`${URL}openai/view-chat/${sessionId}`)
    .catch((err) => {
      let message = ''
      err.code === 'ERR_NETWORK'
        ? (message = 'Fetching chat failed, Reload the page.')
        : (message = `${err.code} + " : " + ${err.message}`)
      store.dispatch(
        setToasterAlert({
          message,
          show: true,
          type: 'error'
        })
      )
      return err
    })
}

export const chatPrompt = async (messageId) => {
  try {
    return await axiosinstance.get(`${URL}openai/chat-prompt/${messageId}`)
  } catch (err) {
    return err
  }
}

export const getPeopleList = async (data) => {
  return axiosinstance.post(`${URL}people/peopleList`, data).catch((err) => {
    return err
  })
}

export const getPeopleResume = async (id) => {
  return axiosinstance
    .post(`${URL}people/peopleList/${encodeURIComponent(id)}`)
    .catch((err) => {
      return err
    })
}

export const setPeopleVerify = async (data) => {
  return axiosinstance.post(`${URL}people/verify_resume`, data).catch((err) => {
    return err
  })
}

export const deleteMasterResume = async (id, deleteType) => {
  return axiosinstance
    .delete(
      `${URL}people/delete/${encodeURIComponent(
        id
      )}?delete_type=${encodeURIComponent(deleteType)}`
    )
    .catch((err) => {
      return err
    })
}

export const deleteMasterResumeMapping = async (data) => {
  return axiosinstance.post(`${URL}people/detach_people`, data).catch((err) => {
    return err
  })
}

export const getLibrarySuggestions = async (data, axiosProps) => {
  return axiosinstance
    .post(`${URL}library/suggest`, data, axiosProps)
    .catch((err) => {
      return err
    })
}

export const updateLibrarySuggestions = async (data) => {
  return axiosinstance.put(`${URL}library/suggest`, data).catch((err) => {
    return err
  })
}

export const getSuggestionSource = async (data) => {
  return axiosinstance
    .post(`${URL}library/suggest/source`, data)
    .catch((err) => {
      return err
    })
}

export const mergePeopleRequest = async (data) => {
  return axiosinstance
    .post(`${URL}people/merge_people_group`, data)
    .catch((err) => {
      return err
    })
}

export const moveResumeRequest = async (data) => {
  return axiosinstance
    .post(`${URL}people/move_people_resume`, data)
    .catch((err) => {
      return err
    })
}

export const getProjectExtract = async (data) => {
  return axiosinstance.post(`${URL}project/reprocess`, data).catch((err) => {
    return err
  })
}

export const ShareRfx = async (data) => {
  return axiosinstance.post(`${URL}rfx/share`, data).catch((err) => {
    return err
  })
}

export const getPublicRFx = async (shareId) => {
  return axios.get(`${URL}rfx/share/${shareId}`).catch((err) => {
    // toast.error(err.code + " : " + err.message);
    let message = ''
    err.code === 'ERR_NETWORK'
      ? (message = 'Fetching RFx document failed, Reload the page.')
      : (message = `${err.code} : ${err.message}`)
    store.dispatch(
      setToasterAlert({
        message,
        show: true,
        type: 'error'
      })
    )
    return err
  })
}

export const rfxAnalysisReportUpdate = async (data) => {
  try {
    return await axiosinstance.put(`${URL}rfx/rfxAnalysisUpdate`, data)
  } catch (err) {
    return err
  }
}

export const generateImages = async (data) => {
  return axiosinstance.post(`${URL}openai/text2image`, data).catch((err) => {
    return err
  })
}

export const saveImagetoAsset = async (data) => {
  return axiosinstance.post(`${URL}asset/save_asset`, data).catch((err) => {
    return err
  })
}

export const getAllProject = async (data) => {
  return axiosinstance.post(`${URL}project/allproject`, data).catch((err) => {
    return err
  })
}

export const mergeProjects = async (data) => {
  return axiosinstance.post(`${URL}project/mergeproject`, data).catch((err) => {
    return err
  })
}

export const getTagCenter = async () => {
  return axiosinstance.get(`${URL}tagcenter/`).catch((err) => {
    return err
  })
}
export const getTagCenterSettings = async () => {
  return axiosinstance.get(`${URL}tagcenter/settings`).catch((err) => {
    return err
  })
}

export const postTagCenterKey = async (data) => {
  return axiosinstance.post(`${URL}tagcenter/key`, data).catch((err) => {
    return err
  })
}

export const postTagCenterValue = async (data) => {
  return axiosinstance.post(`${URL}tagcenter/`, data).catch((err) => {
    return err
  })
}

export const addMultiTagCenterValue = async (data) => {
  return axiosinstance.post(`${URL}tagcenter/multiadd`, data).catch((err) => {
    return err
  })
}

export const postMultiTagCenterValue = async (data) => {
  return axiosinstance.post(`${URL}tagcenter/multi`, data).catch((err) => {
    return err
  })
}

export const createCollections = async (data) => {
  return axiosinstance.post(`${URL}collections/create`, data).catch((err) => {
    return err
  })
}

export const getCollections = async (id = 'root') => {
  return axiosinstance
    .get(`${URL}collections/${encodeURIComponent(id)}`)
    .catch((err) => {
      return err
    })
}

export const putCollections = async (data) => {
  return axiosinstance.put(`${URL}collections/`, data).catch((err) => {
    return err
  })
}

export const postCollectionData = async (data, axiosProps) => {
  return axiosinstance
    .post(`${URL}collections/content`, data, axiosProps)
    .catch((err) => {
      return err
    })
}

export const deleteFromCollection = async (data) => {
  return axiosinstance.post(`${URL}collections/remove`, data).catch((err) => {
    return err
  })
}

export const updateCollectionName = async (data) => {
  return axiosinstance
    .post(`${URL}library/update_collection_name`, data)
    .catch((err) => {
      return err
    })
}

export const copyFilesToCollection = async (data) => {
  return axiosinstance.post(`${URL}collections/copy`, data).catch((err) => {
    return err
  })
}

export const moveFilesToCollection = async (data) => {
  return axiosinstance.post(`${URL}collections/move`, data).catch((err) => {
    return err
  })
}

export const updateFileAccess = async (data) => {
  return axiosinstance
    .post(`${URL}collections/document_access`, data)
    .catch((err) => {
      return err
    })
}

export const getFileAccess = async (id) => {
  return axiosinstance
    .get(`${URL}collections/document_access/${encodeURIComponent(id)}`)
    .catch((err) => {
      return err
    })
}

export const updateResource = async (data) => {
  return axiosinstance
    .post(`${URL}collections/update_document`, data)
    .catch((err) => {
      return err
    })
}

export const deleteCollectionFolder = async (data) => {
  return axiosinstance.post(`${URL}collections/delete`, data).catch((err) => {
    return err
  })
}

export const upscaleAsset = async (data) => {
  return axiosinstance.post(`${URL}asset/upscale_image`, data).catch((err) => {
    return err
  })
}

export const replaceAsset = async (data, axiosProps) => {
  return axiosinstance
    .post(`${URL}asset/replace_upscaled_image`, data, axiosProps)
    .catch((err) => {
      return err
    })
}

export const getDocumentTagValues = async (id, type) => {
  return axiosinstance
    .get(`${URL}tagcenter/document/${type}/${id}`)
    .catch((err) => {
      return err
    })
}

export const ShareAnalytics = async (data) => {
  return axiosinstance.post(`${URL}analytic/share`, data).catch((err) => {
    return err
  })
}

export const getPublicAnalytics = async (shareId) => {
  return axios.get(`${URL}analytic/share/${shareId}`).catch((err) => {
    // toast.error(err.code + " : " + err.message);
    let message = ''
    err.code === 'ERR_NETWORK'
      ? (message = 'Fetching Analytic failed, Reload the page.')
      : (message = `${err.code} : ${err.message}`)
    store.dispatch(
      setToasterAlert({
        message,
        show: true,
        type: 'error'
      })
    )
    return err
  })
}

export const getAnalyticWithoutUser = async (
  shareId,
  startPage = 1,
  endPage = 50,
  domainId
) => {
  return axios
    .get(
      `${URL}analytic/public/${shareId}?start_page=${encodeURIComponent(
        startPage
      )}&end_page=${encodeURIComponent(endPage)}&domain_id=${encodeURIComponent(
        domainId
      )}`
    )
    .catch((err) => {
      // toast.error(err.code + " : " + err.message);
      let message = ''
      err.code === 'ERR_NETWORK'
        ? (message = 'Fetching Analytic failed, Reload the page.')
        : (message = `${err.code} : ${err.message}`)
      store.dispatch(
        setToasterAlert({
          message,
          show: true,
          type: 'error'
        })
      )
      return err
    })
}

export const getChartDataWithoutUser = async (data) => {
  try {
    return await axios.post(`${URL}analytic/public/retrieve_charts_data`, data)
  } catch (err) {
    return err
  }
}

export const getWinStrategyWithoutUser = async (data) => {
  try {
    return await axios.post(`${URL}analytic/public/winStrategy`, data)
  } catch (err) {
    return err
  }
}

export const triggerAnalyticsCompliance = async (data) => {
  return axiosinstance
    .post(`${URL}analytic/trigger_compliance`, data)
    .catch((err) => {
      return err
    })
}

export const getAnalyticsCompliance = async (data) => {
  return axiosinstance.post(`${URL}analytic/compliance`, data).catch((err) => {
    return err
  })
}

export const getAnalysedRFxList = async (data) => {
  return axiosinstance
    .post(`${URL}analytic/getAnalysedRFx`, data)
    .catch((err) => {
      return err
    })
}

export const updateAnalyticsCompliance = async (data) => {
  return axiosinstance
    .post(`${URL}analytic/update_analytics_compliance`, data)
    .catch((err) => {
      return err
    })
}

export const getAllProjectIds = async (data) => {
  return axiosinstance
    .post(`${URL}project/allproject_ids`, data)
    .catch((err) => {
      return err
    })
}

export const multiDocuments = async (data) => {
  return axiosinstance.post(`${URL}collections/multi`, data).catch((err) => {
    return err
  })
}

export const saveMasterPeopleNotes = async (data) => {
  return axiosinstance.post(`${URL}people/save_notes`, data).catch((err) => {
    return err
  })
}

export const saveProjectNotes = async (data) => {
  return axiosinstance
    .post(`${URL}project/update_project_notes`, data)
    .catch((err) => {
      return err
    })
}

export const hidePageData = async (data) => {
  return axiosinstance
    .post(`${URL}proposals/hide-page-data`, data)
    .catch((err) => {
      return err
    })
}

export const getParticularProject = async (id) => {
  return axiosinstance
    .post(`${URL}project/particular_project/${id}`)
    .catch((err) => {
      return err
    })
}

export const getParticularMasterPeople = async (id) => {
  return axiosinstance.post(`${URL}people/master_people/${id}`).catch((err) => {
    return err
  })
}

export const changeMasterPeopleName = async (data) => {
  return axiosinstance
    .post(`${URL}people/update_emp_name`, data)
    .catch((err) => {
      return err
    })
}

export const changePeopleInfo = async (data) => {
  return axiosinstance
    .post(`${URL}people/update_people_group`, data)
    .catch((err) => {
      return err
    })
}

export const getClientsList = async (data) => {
  return axiosinstance.post(`${URL}clients/allclient`, data).catch((err) => {
    return err
  })
}

export const addProjectToClient = async (data) => {
  return axiosinstance.post(`${URL}clients/addProject`, data).catch((err) => {
    return err
  })
}

export const getAllProposalIds = async (data) => {
  return axiosinstance
    .post(`${URL}project/allproposal_ids`, data)
    .catch((err) => {
      return err
    })
}

export const getAllRfxIds = async (data) => {
  return axiosinstance.post(`${URL}project/allrfx_ids`, data).catch((err) => {
    return err
  })
}

export const deleteProjectFromClient = async (data) => {
  return axiosinstance
    .post(`${URL}clients/removeProject`, data)
    .catch((err) => {
      return err
    })
}

export const mergeClients = async (data) => {
  return axiosinstance.post(`${URL}clients/mergeclient`, data).catch((err) => {
    return err
  })
}

export const updatePeopleResume = async (data) => {
  return axiosinstance
    .post(`${URL}people/update_people_resume`, data)
    .catch((err) => {
      return err
    })
}

export const uploadFileToS3 = async (data) => {
  return axiosinstance
    .post(`${URL}people/upload_resume_screenshot`, data)
    .catch((err) => {
      return err
    })
}

export const getIndividualClient = async (data) => {
  return axiosinstance
    .post(`${URL}clients/individualClient`, data)
    .catch((err) => {
      return err
    })
}

export const getClearBitData = async (data) => {
  return axiosinstance
    .post(`${URL}clients/getEnrichment`, data)
    .catch((err) => {
      return err
    })
}

export const getClearBitDomainData = async (data) => {
  return axiosinstance
    .post(`${URL}clients/verifyEnrichment`, data)
    .catch((err) => {
      return err
    })
}

export const updateClientData = async (data) => {
  return axiosinstance
    .post(`${URL}clients/updateEnrichment`, data)
    .catch((err) => {
      return err
    })
}

export const getChatVoiceConfigs = async () => {
  return axiosinstance.get(`${URL}chat_settings/voices`).catch((err) => {
    return err
  })
}

export const postChatVoiceConfigs = async (data) => {
  return axiosinstance.post(`${URL}chat_settings/voices`, data).catch((err) => {
    return err
  })
}

export const getChatInstructions = async () => {
  return axiosinstance
    .post(`${URL}chat_settings/chat_instructions`)
    .catch((err) => {
      return err
    })
}

export const getChatDomainPrompts = async () => {
  return axiosinstance
    .get(`${URL}chat_settings/domain_prompts`)
    .catch((err) => {
      return err
    })
}

export const postChatDomainPrompts = async (data) => {
  return axiosinstance
    .post(`${URL}chat_settings/domain_prompts`, data)
    .catch((err) => {
      return err
    })
}

export const getAllProposalNames = async () => {
  return axiosinstance
    .post(`${URL}proposals/get_all_proposal_names`)
    .catch((err) => {
      return err
    })
}

export const getAllRfxNames = async () => {
  return axiosinstance.post(`${URL}rfx/get_all_rfx_names`).catch((err) => {
    return err
  })
}

export const generateCalendarEvents = async (data) => {
  return axiosinstance
    .post(`${URL}rfx/generate_calendar_events`, data)
    .catch((err) => {
      return err
    })
}

export const getWorkspaces = async (data, axiosProps = {}) => {
  return axiosinstance
    .post(`${URL}workspace/`, data, axiosProps)
    .catch((err) => {
      return err
    })
}

export const editWorkspace = async (data, axiosProps = {}) => {
  return axiosinstance
    .post(`${URL}workspace/actions`, data, axiosProps)
    .catch((err) => {
      return err
    })
}

export const getWorkspace = async (id) => {
  return axiosinstance.get(`${URL}workspace/${id}`).catch((err) => {
    return err
  })
}

export const workspaceActionsFiles = async (data) => {
  return axiosinstance
    .post(`${URL}workspace/addon_files`, data)
    .catch((err) => {
      return err
    })
}

export const workspaceActionsDrafts = async (data) => {
  return axiosinstance
    .post(`${URL}workspace/addon_draft`, data)
    .catch((err) => {
      return err
    })
}

export const getWorkspaceDraft = async (workspaceId, id) => {
  return axiosinstance
    .get(`${URL}workspace/draft/${workspaceId}/${id}`)
    .catch((err) => {
      return err
    })
}

export const rerunDraft = async (data) => {
  return axiosinstance
    .post(`${URL}workspace/draft_rerun`, data)
    .catch((err) => {
      return err
    })
}

export const getDraftStatus = async (id) => {
  return axiosinstance.get(`${URL}workspace/draft_logs/${id}`).catch((err) => {
    return err
  })
}

export const saveDraftConfig = async (data) => {
  return axiosinstance
    .post(`${URL}workspace/storyboards`, data)
    .catch((err) => {
      return err
    })
}

export const getStoryboardGenerationHistory = async (id) => {
  return axiosinstance
    .get(`${URL}workspace/storyboard_history/${id}`)
    .catch((err) => {
      return err
    })
}

export const getRFXPainPoints = async (id) => {
  return axiosinstance
    .post(`${URL}rfx/get_rfx_pain_points`, {
      id
    })
    .catch((err) => {
      return err
    })
}

export const fetchRFXPainPoints = async (id) => {
  return axiosinstance
    .post(`${URL}rfx/fetch_rfx_pain_points`, {
      id
    })
    .catch((err) => {
      return err
    })
}
